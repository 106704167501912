window.esign = window.esign || {};

esign.init = function () {

  $('html').removeClass('no-js').addClass('js');

  Response.create({
    prop: "width",
    prefix: "min-width- r src",
    breakpoints: [767,0],
    lazy: true
  });

  esign.gaDevelopment();
  esign.cacheSelectors();

  esign.navigation();
  esign.language();
  esign.responsiveVideos();

  esign.formValidation();
  esign.formAjax();
  esign.formAjaxRecaptcha();

  esign.scrollTo();
  esign.dotdotdot();
  esign.accordion();
  esign.headerColor();
  esign.apiFetch();
  esign.productGrid();
  esign.parallax();
  esign.fileUpload();
  esign.customDate();

  esign.littleMiracle();
  esign.multiStepGiveaway();
};

esign.multiStepGiveaway = function () {
  var giveaway = document.querySelector('.js-giveaway');

  if (giveaway) {
    var button1 = document.querySelector('.js-goto-giveaway-step-2');
    var step1 = document.querySelector('.js-giveaway-step-1');
    var step2 = document.querySelector('.js-giveaway-step-2');

    button1.addEventListener('click', function (e) {
      var promocodeFrom = $("#form-promocode");
      promocodeFrom.validationEngine();
      var valid = promocodeFrom.validationEngine('validate');

      if (valid) {
        step1.style.display = 'none';
        step2.style.display = 'block';
      }
    });
  }
};

esign.littleMiracle = function () {
  if (!document.querySelector('.little-miracle')) {
    return;
  }

  var nav = document.querySelector('.main-nav__wrap');
  var form = document.querySelector('#form-little-miracle');

  var createListItem = function (text, url) {
    var listItem = document.createElement('li');
    var listItemUrl = document.createElement('a');
    listItemUrl.href = url;
    listItemUrl.innerHTML = text;
    listItem.appendChild(listItemUrl);
    return listItem;
  };

  var firstListItem = createListItem(form.dataset.copyRegulations);
  var secondListItem = createListItem(form.dataset.copyHome, form.dataset.urlHome);

  firstListItem.firstElementChild.addEventListener('click', function (e) {
    e.preventDefault();
    $(window).scrollTo('hr');
  });

  nav.appendChild(firstListItem);
  nav.appendChild(secondListItem);
};

esign.cacheSelectors = function () {
  esign.cache = {
    // general
    isMobile: esign.isMobile(),

    // navigation
    $header: $('.page-header'),
    $nav: $('.main-nav__wrap'),

    animationDuration: 300,
  };
};

esign.popup = function (cookie) {
  var $popupTrigger = $('.shop-modal-trigger');

  if(cookie.get('popup-store') !== 'true') {
    cookie.set('popup-store', 'true');
    $popupTrigger.modaal();
    $popupTrigger.trigger('click');
  }
};


esign.parallax = function () {
  var controller = new ScrollMagic.Controller({
    globalSceneOptions: {
      triggerHook: 'onLeave'
    }
  });

  var heros = document ? document.querySelectorAll('.hero') : false;

  if (heros && heros.length) {
    for (var i = 0; i < heros.length; i++) {
      var $bcg = $(heros[i]).find('.hero__background');

      var heroParallaxScene = new ScrollMagic.Scene({
        triggerElement: heros[i],
        triggerHook: 1,
        duration: '100%'
      })
        .setTween(TweenMax.to($bcg, 0.5, {y: '30%', ease: Linear.easeNone}))
        // .addIndicators()
        .addTo(controller);
    }
  }

  var elements = document ? document.querySelectorAll('.parallax-item') : false;

  if (elements && elements.length) {
    for (var i = 0; i < elements.length; i++) {
      var $element = $(elements[i]);

      var elementsScene = new ScrollMagic.Scene({
        triggerElement: elements[i],
        duration: '100%',
        offset: -1000,
        triggerHook: 0
      })
        .setTween(TweenMax.from($element, 1, {y: '30%', ease: Linear.easeNone}))
        // .addIndicators()
        .addTo(controller);
    }
  }

  var elements2 = document ? document.querySelectorAll('.parallax-item2') : false;

  if (elements2.length) {
    for (var i = 0; i < elements2.length; i++) {
      var $element2 = $(elements2[i]);

      var elements2Scene = new ScrollMagic.Scene({
        triggerElement: elements2[i],
        duration: '100%',
        offset: -1000,
        triggerHook: 0
      })
        .setTween(TweenMax.from($element2, 1, {y: '15%', ease: Linear.easeNone}))
        // .addIndicators()
        .addTo(controller);
    }
  }
}

esign.productGrid = function () {
  var $productGrid = $('.product-grid');

  if ($productGrid.length) {
    var $gridItems = $productGrid.find('.grid__item');
    var count = $gridItems.length;
    var delayInit = 2000;
    var delayBetween = 300;
    var delayTotal = 0;

    $gridItems.each(function () {
      var $productLogo = $(this).find('.product__logo, .product__bg');

      $productLogo.imagesLoaded({
        background: true
      }, function () {
        $productLogo.addClass('loaded');
      });
    });

    productGridNavigation();
  }

  function productGridNavigation() {
    window.setTimeout(function () {
      $productGrid.addClass('animating');

      $gridItems.each(function (index) {
        var $gridItem = $(this);
        var delay = (delayBetween - (delayBetween / Math.pow(1.5, index))) * 3;

        window.setTimeout(function () {
          $gridItem.removeClass('hover');
        }, delay);
      });

      delayTotal = (delayBetween - (delayBetween / Math.pow(1.5, count - 1))) * 3

      window.setTimeout(function () {
        $productGrid.removeClass('animating');
      }, delayTotal);
    }, delayInit);
  }
};

esign.apiFetch = function () {
  var truncate = function (text) {
    var string = text.substring(0, 120);
    if (text.length > 120) {
      string += '&hellip;'
    }

    return string;
  };

  $('.js-api-fetch').each(function() {
    var $this = $(this);

    var api = $this.data('api');
    var limit = $this.data('limit'); // optional limitation
    var itemClass = $this.data('item-class');

    $.ajax({
      url: api,
      data: {},
      dataType: 'jsonp',
      success: function(data) {
        if (data && data.length) {
          // get length of files
          var length = limit ? limit : data.length;
          var items = '';

          for (var i = 0; i < length; i++) {
            var el = data[i];
            if (el) {
              items += '<div class="' + itemClass + '">' +
                '<a href="' + el.url + '" target="_blank" class="social-item-link" rel="noopener">' +
                '<img src="' + el.photo + '" class="social-item-link__image" alt="' + truncate(el.text) + '" />' +
                '<span class="social-item-link__content"><span class="social-item-link__text">' +
                truncate(el.text) +
                '</span></span>' +
                '</a>' +
                '</div>';
            }
          }

          $this.html(items);
        }
      }
    });
  });
};

esign.headerColor = function () {
  var offset = 60;

  $(window).scroll(function(){
    esign.scrollPosition(offset);
  }).scroll();
};

esign.scrollPosition = function (offset) {
  var $header = esign.cache.$header;

  if ($header.length) {
    var headerPosition = $header.offset().top;
    var headerPeight = $header.outerHeight();

    $('.js-background-element').each(function () {
      var $background = $(this);
      var backgroundPosition = $background.offset().top;
      var backgroundHeight = $background.outerHeight();
      var headerColor = $background.attr('data-color');

      if (headerColor) {
        if (headerPosition + headerPeight + offset < backgroundPosition) {
          $header.removeClass('global-color-' + headerColor);
        } else if (headerPosition + offset > backgroundPosition + backgroundHeight) {
          $header.removeClass('global-color-' + headerColor);
        } else {
          $header.addClass('global-color-' + headerColor);
        }
      }
    });
  }
};

esign.accordion = function () {
  var $button = $('.product-hero__description + .link');
  var $description = $('.product-hero__description');
  var $descriptionInner = $description.find('.rte');

  $button.click(function(e) {
    e.preventDefault();

    var $this = $(this),
      $target = $description;

    if($target !== null) {
      $this.toggleClass('active');
      $target.toggleClass('active');
    }
  });

  if ($description.innerHeight() > $descriptionInner.innerHeight()) {
    $button.css('display', 'none');
  }
};

esign.fileUpload = function () {
  var $fileInput = $('.js-file-input');
  var $label = $fileInput.find('.label');
  var $defaultLabel = $label.text();

  $fileInput.on('change', function (e) {
    var files = e.target.files;
    if (files[0].name) {
      $label.text(files[0].name);
    } else {
      $label.text($defaultLabel);
    }
  });
};

esign.customDate = function () {
  var $dateField = $('.js-custom-date');
  if($(window).width() < 767)
  {
    $dateField.attr('type', 'date');
  } else {
    if ($dateField.val() === '') {
      $dateField.attr('type', 'text');
    }
  }
};

esign.dotdotdot = function () {
  $('.js-dot').dotdotdot({
    watch: true,
    tolerance: 15
  });
};

esign.scrollTo = function () {
  $('.js-scroll-trigger').on('click', function(e) {
    e.preventDefault();

    var $this = $(this),
      offset = $this.data('offset'),
      target = $this.data('target');

    if (offset === undefined) {
      offset = 0;
    }

    if (target !== undefined) {
      $(window).scrollTo($('#' + target), 1000, {
        axis: 'y',
        offset: offset
      });
    }
  });
};

esign.navigation = function () {
  Response.crossover('width', function() {
    if(Response.band(767)) {
      $('.main-nav__trigger').modaal('close');
      esign.cache.$nav.css('display', 'inline-block');
    } else {
      $('.main-nav__trigger').modaal();
      esign.cache.$nav.css('display', 'none');
    }
  });

  // Change size on scroll
  $(window).scroll(function () {
    scrollPosition();
  }).scroll();

  function scrollPosition() {
    var className1 = 'menu-hidden';
    var className2 = 'small';
    var $header = $('.page-header');

    if (documentTopPosition() > 50) {
      $header.addClass(className1);
    } else {
      $header.removeClass(className1);
    }

    if (documentTopPosition() > 250) {
      $header.addClass(className2);
    } else {
      $header.removeClass(className2);
    }
  }

  function documentTopPosition() {
    return $(document).scrollTop();
  }
};

esign.language = function () {
  var $languageMenu = $('.language');
  var $languageTrigger = $languageMenu.find('.language__trigger');
  var $languageDropdown = $languageMenu.find('.language__wrap');

  $languageTrigger.on('click', function (e) {
    e.preventDefault();
    $languageMenu.toggleClass('open');
    $languageDropdown.fadeToggle(esign.cache.animationDuration);
  });

  $(document).on('click', function(e) {
    if(!$(e.target).closest('.language').length) {
      if ($languageMenu.hasClass('open')) {
        $languageMenu.removeClass('open');
        $languageDropdown.fadeOut(esign.cache.animationDuration);
      }
    }
  });
};

esign.responsiveVideos = function () {
  $('iframe[src*="youtube.com/embed"], iframe[src*="youtube-nocookie.com/embed"], iframe[src*="player.vimeo"]').wrap('<div class="video-container"></div>');
};


// ---------------------------------------
// ----------------- FORMS & Captcha -----
// ---------------------------------------

// normaal formulier versturen met post
esign.formAjax = function () {
  $('.form-ajax').submit(function(e) {
    var $form = $(this);
    $form.find('button,input[type="submit"]').attr("disabled", "disabled");

    $.post($form.attr('action'), $form.serializeArray(), function(data) {
      if(data.errors === false) {
        $form.html(data.result);
      } else {
        $form.find('.result').html(data.result);
        $form.find('button, input[type="submit"]').removeAttr('disabled');
      }
    });

    e.preventDefault();
    return false;
  });
};

// recaptcha formulier versturen met post
esign.formAjaxRecaptcha = function () {
  $('.form-ajax-recaptcha').submit(function (e) {
    e.preventDefault();
    var $form = $(this);

    if ($form.hasClass('validate')) {
      if ($form.validationEngine('validate') === false) {
        return false;
      }
    }

    if ($form.find('[name="g-recaptcha-response"]').length && $form.find('[name="g-recaptcha-response"]').val() !== '') {
      grecaptcha.reset();
    }

    grecaptcha.execute($form.find('.g-recaptcha').data('widgetid'));

    return false;
  });
};

// recaptcha submit handler
esign.recaptchaFormSend = function (form) {
  $form = $(form);
  $form.find('input[type="submit"], button').attr('disabled', 'disabled');

  $.ajax({
    type: 'POST',
    url:$form.attr('action'),
    data: new FormData($form[0]),
    processData: false,
    // Allows us to get file fields via JS
    contentType: false,
    success: function(data) {
      $('li').removeClass('error');
      var $result = $form.find('.result');

      if (data.errors === false) {

          if($form.hasClass('b2b-cart__form')) {
            Cart.empty();
            $(".result").html(data.result);

          }else  {
            $form.html(data.result);
          }

        if (typeof ga === "function") {
          ga('send', 'pageview', $form.attr('action'));
        }

        //todo GTM trigger event

      } else {
        $result.html(data.result);
        if (data.fields) {
          $.each(data.fields, function (i, field) {
            $('input[name="' + field + '"],textarea[name="' + field + '"]').addClass('error');
          });
        }
      }

      $form.find('input[type="submit"], button').removeAttr('disabled');
    }
  }).fail(function (response) {
    alert('Error: ' + response.responseText);
  });
};

// recaptcha submit handler per formulier
var submitRecaptchaFormContact = function (token) {
  esign.recaptchaFormSend($('#form-contact'));
};
var submitRecaptchaFormNewsletter = function (token) {
  esign.recaptchaFormSend($('#form-newsletter'));
};
var submitRecaptchaFormLittleMiracle = function (token) {
  esign.recaptchaFormSend($('#form-little-miracle'));
};
var submitRecaptchaFormArizona = function (token) {
  esign.recaptchaFormSend($('#form-arizona'));
};
var submitRecaptchaFormTheGutsyCaptainKombucha = function (token) {
  esign.recaptchaFormSend($('#form-the-gutsy-captain-kombucha'));
};

function expandForm() {
  var optionalInputs = $('.form__item--optional');
  optionalInputs.toggle();
  var el = $('.js-expand-form');
  var copy;
  if($('.form__item--optional').is(':visible')) {
    copy = '- ' + el.data('copy-remove-address');
  } else {
    // Clear inputs when hiding the optional fields
    optionalInputs.children('input').val('');
    copy = '+ ' + el.data('copy-add-address');
  }
  $('.js-expand-form').html(copy);
}

// explicit recaptcha methode (zodat er meerdere op 1 pagina kunnen)
var onloadReCaptchaCallback = function () {
  $('.g-recaptcha').each(function (i, el) {
    var attributes = {
      'sitekey': $(el).data('sitekey'),
      'size': $(el).data('size'),
      'callback': $(el).data('callback')
    };

    var widgetid = grecaptcha.render(el, attributes);
    $(el).data('widgetid', widgetid);
  });
};


// ---------------------------------------
// ----------------- FORMS validation ----
// ---------------------------------------

esign.formValidation = function () {
  if(esign.cache.isMobile) {
    $('.validate').validationEngine();
  } else {
    $('.validate').validationEngine({scroll: false});
  }
};


// ---------------------------------------
// ----------------- UTILS -----------
// ---------------------------------------
esign.isMobile = function () {
  var deviceAgent = navigator.userAgent.toLowerCase(),
      isMobile = (deviceAgent.match(/(iphone|ipod|ipad)/) ||
        deviceAgent.match(/(android)/)  ||
        deviceAgent.match(/(iemobile)/) ||
        deviceAgent.match(/blackberry/i) ||
        deviceAgent.match(/bada/i)) ||
        (/OS [1-4]_[0-9_]+ like Mac OS X/i.test(navigator.userAgent));

  if(isMobile) {
    $('html').addClass('mobile');
  } else {
    $('html').addClass('no-mobile');
  }

  return isMobile;
};


// ---------------------------------------
// ----------------- DEV TOOLS -----------
// ---------------------------------------

// Log ga calls in development
esign.gaDevelopment = function() {
  if (typeof ga === typeof undefined) {
    window.ga = function() {
      var argumentsArray = [];
      for (var key in arguments) {
        if (arguments.hasOwnProperty(key)) {
          argumentsArray.push(arguments[key]);
        }
      }

      var msg = '[GA DEV] Call with arguments [' + argumentsArray.join(',') + ']';
      console.log('%c' + msg, 'background: #ff9800; color: #fff;');
    };
  }
};

require([
  'requirejs/require',
  'jquery/dist/jquery',
  'js/polyfills/native-console',
  'response.js/response',
  'jquery-touchswipe/jquery.touchSwipe',
  'js/libs/validation/jquery.validationEngine',
  'jquery.scrollto/jquery.scrollTo',
  'jquery.dotdotdot/dist/jquery.dotdotdot',
  'modaal/dist/js/modaal',
  'imagesloaded/imagesloaded.pkgd',
  'js/b2b-shop',
], function() {
  // Initialize on docready
  $(esign.init);
});

requirejs( [
  'js-cookie/src/js.cookie'
], function(cookie) {
  esign.popup(cookie);
});

define("../resources/assets/js/esign", function(){});

